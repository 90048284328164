import './web.config'
import 'react-app-polyfill/stable'
import 'core-js';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./scss/style.scss";
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth_new/authConfig";
import { ReactSession } from 'react-client-session';
const CryptoJS = require("crypto-js");
import axios from 'axios';
ReactSession.setStoreType("localStorage");
const RootUrl = process.env.REACT_APP_API_URL
const random = process.env.REACT_APP_SECRETO
 
var requestId = CryptoJS.AES.encrypt(JSON.stringify(random), process.env.REACT_APP_KEY).toString();
let data = ''
let data1 = ''
let data2 = ''
let uri = ''
let clientId = ''
let redirecturi = ''
 
function Loader() {
  return (
<div className="loader-container">
<div className="loader">Loading...</div>
</div>
  );
}
 
function AppWrapper() {
  const [isLoading, setIsLoading] = useState(true);
 
  useEffect(() => {
    async function fetchData() {
      try {
        await axios.get(RootUrl+'/msal', {
          headers: {
            "requestId": requestId
          },
        })
          .then(response => {
            data = response.data[0]
            data1 = response.data[1]
            data2 = response.data[2]
            uri = CryptoJS.AES.decrypt(data, process.env.REACT_APP_KEY);
            clientId = CryptoJS.AES.decrypt(data1, process.env.REACT_APP_KEY);
            redirecturi = CryptoJS.AES.decrypt(data2, process.env.REACT_APP_KEY);
          })
          .catch(error => {
            console.log(error);
          });
 
        const msalConfig = {
          auth: {
            clientId: JSON.parse(clientId.toString(CryptoJS.enc.Utf8)),
            authority: JSON.parse(uri.toString(CryptoJS.enc.Utf8)),
            redirectUri: JSON.parse(redirecturi.toString(CryptoJS.enc.Utf8)),
          },
          cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: false,
          },
        };
 
        const msalInstance = new PublicClientApplication(msalConfig);
 
        setIsLoading(false);
        ReactDOM.render(
<React.StrictMode>
<MsalProvider instance={msalInstance}>
<Provider store={store}>
<App />
</Provider>
</MsalProvider>
</React.StrictMode>,
          document.getElementById('root'),
        );
      } catch (error) {
        console.log(error);
      }
    }
 
    fetchData();
  }, []);
 
  return isLoading ? <Loader /> : <App />;
}
 
ReactDOM.render(
<AppWrapper />,
  document.getElementById('root'),
);
 
reportWebVitals();
