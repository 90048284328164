import React, { Suspense,useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react'
import axios from 'axios';
import { ReactSession } from 'react-client-session';

ReactSession.setStoreType("localStorage");

const RootUrl = process.env.REACT_APP_API_URL
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const AbiLayout = React.lazy(() => import('./layout/AbiLayout'))

function  HandleLogin (instance)  {
  const msalInstance = instance;
   msalInstance.handleRedirectPromise()
  .then((tokenResponse) => {
      if (!tokenResponse) {
          const accounts = msalInstance.getAllAccounts();
          if (accounts.length === 0 || !accounts) {
            
              // No user signed in
              msalInstance.loginRedirect();
          }
      } else {
          // Do something with the tokenResponse
      }
  })
  .catch(err => {
      // Handle error
      console.error(err);
  });
}
function HitMe(user, setUser){
  const {  accounts } = useMsal();
  React.useEffect(() => {
    if(!user){
      
      
      if(accounts.length){
       let user =  accounts[0]
       
       const username = user.username
       const email = user.username
      
       const fullname = user.name
       
        axios.post(RootUrl+'/users/auto_add_new', { username, email, fullname })
          .then(response => console.log('response',response),
          )
          .catch(error => {
          });
      }
    }
  }, [user]);
  React.useEffect(() => {
    if(accounts.length != 0){
      if(!user){
        let email = accounts[0].username
      
        let username = accounts[0].username
      
        let fullname = accounts[0].name
        axios.post(RootUrl+`/auth/signin/`, { email }
        )
        .then(response =>{
          if(response.data.success == false){
            //  Hit Add Api if user not found **************************
            
            axios.post(RootUrl+'/users/auto_add_new', { username, email, fullname })
            .then(response => console.log('response',response),
            )
            .catch(error => {
            });
              //  Hit Add Api if user not found  ************************
          }else{
            ReactSession.set("token", response.data.token);
            setUser(response.data.user)
          }
      })
      .catch(error => {
        window.location.reload();
      })
      }
    }

  }, [accounts]);
 

}


function App() {
  const { instance } = useMsal();
  const [user, setUser] = useState();
    return (
      <>
        <BrowserRouter>

          <Suspense fallback={loading}>
          <UnauthenticatedTemplate>
            {() => HandleLogin(instance)}
          </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
              {HitMe(user, setUser)}
              {
              user? 
              user.role_id != 2? 
               <Routes>
                <Route  path="/admin/*" name="Dashboard" element={<DefaultLayout />} > </Route>
                <Route path="*" name="" element={<AbiLayout />} />
                <Route path="" name="" element={<AbiLayout />} />
              </Routes>
             
              :
              <Routes>
              <Route path="*" name="" element={<AbiLayout />} />
              <Route path="" name="" element={<AbiLayout />} />
              </Routes>
              : ""
              }
             
              

            </AuthenticatedTemplate>
          </Suspense>
        </BrowserRouter>
      </>
    )
}

export default App
